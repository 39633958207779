import { odiencePathIndicatorKey, paths } from "@/routerPaths";
import { globalStyles } from "@/styles/global.styles";
import {
  ProvisionCallback,
  ProvisionFirstStepCallback,
  ProvisionZeroStepCallback,
} from "@/types/provisioning";
import { atoms } from "@/utils/helpers/atoms";
import { getCookieRememberMe } from "@/utils/helpers/cookiesStorage";
import { formatPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import {
  DeviceNotification,
  LOGIN_RESULT_NOTIFICATION_STATUS_CODE,
  ProvisioningSteps,
  WebGwResponse,
  WebGwResponseOTP,
} from "@/utils/helpers/provisionRequest";
import { logoutEvent } from "@/utils/helpers/removeDevice";
import { setNewCurrentTabActive } from "@/utils/hooks/useIsTabActive";
import { Global } from "@emotion/react";
import { useAtom } from "jotai";
import { useLayoutEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { checkAndRefetchConfig, getConfig } from "../../utils/helpers/config";
import {
  getDeviceName,
  getLocalAccessToken,
  getLocalUser,
  removeLocalAccessToken,
  setImpi,
  setLocalAccessToken,
  setLocalIsFirstTimeUser,
  setPassword,
  setShowProfileScreen,
  setSipUser,
  setUsername,
} from "../../utils/helpers/localstorage";
import {
  abortProv,
  login,
  provWait,
  provWaitAfterOtp,
  register,
  relogin,
} from "../../utils/helpers/loginAndCaps";
import ReloginPage from "./form/ReloginPage";
import Step0 from "./form/Step0";
import Step1 from "./form/Step1";
import Step2 from "./form/Step2";
import Step3 from "./form/Step3";
import Step4 from "./form/Step4";
import StepRemoveDevice from "./form/StepRemoveDevice";
import StepTransferSession from "./form/StepTransferSession";
import { Root, provisioningBody } from "./index.style";

const PROVISIONED_SUCCESS = "provisioned";
const LOGGED_IN_SUCCESS = "loggedin";
const GET_DEVICE_LIST = "get_device_list";

const Provisioning = () => {
  // Directly set the disconnected page when user was kicked out by server
  const [current, setCurrent] = useState(0);
  const [result, setResult] = useAtom(atoms.provisioning.result);
  const [currentUser, _setCurrentUser] = useAtom(atoms.provisioning.user);
  const [devices, setDevices] = useAtom(atoms.provisioning.devices);
  const [maxDevices, setMaxDevices] = useAtom(atoms.provisioning.maxDevices);
  const rememberMe = getCookieRememberMe();
  const [params] = useSearchParams();
  const fromOdiencePreview = params.has(odiencePathIndicatorKey);
  const odienceEventId = localStorage.getItem("odienceEventIdKey");

  const provisionAbortController = useRef(new AbortController());

  const abortProvisioning = async () => {
    if (
      current === ProvisioningSteps.StepRemoveDevice ||
      provisionAbortController.current.signal.aborted ||
      result
    ) {
      return;
    }

    provisionAbortController.current.abort();

    const accessToken = getLocalAccessToken();

    if (accessToken === "" || !accessToken) {
      console.error("No accessToken, provisioning failed");
      return;
    }

    await abortProv(accessToken);
  };

  // Always abort current request, in case user hits back button or go out of this component
  useLayoutEffect(() => {
    void abortProvisioning();
  });

  const loginAfterProv = async (
    accessToken,
    user,
    firstTimeUser?: boolean,
    forceConfig = false
  ): Promise<boolean> => {
    // these can be done in parallel. They are awaited after login.
    const configsPromise = getConfig(forceConfig);

    const loginResponse = await login(accessToken);
    const loginJson = loginResponse?.body
      ? ((await loginResponse?.json()) as WebGwResponse)
      : undefined;
    if (
      loginResponse?.ok &&
      loginJson?.notificationList?.loginResultNotification?.result ===
        LOGGED_IN_SUCCESS
    ) {
      console.debug(
        `Registered and logged in successfully, first time user is ${firstTimeUser}`
      );

      if (firstTimeUser) {
        setLocalIsFirstTimeUser();
      }

      const configs = await configsPromise;
      console.log("loaded configs:", configs);
      if (configs) {
        const impi = configs["application/3gpp_ims/private_user_identity"];
        const username = configs["application/3gpp_ims/ext/gsma/username"];
        const password = configs["application/3gpp_ims/ext/gsma/userpwd"];
        //Setting the encrypted IMPI in our localstorage
        setImpi(impi);
        setUsername(username);
        setPassword(password);
        const sipUser = await formatPhoneNumber(user, "SIP");
        setSipUser(sipUser);
        setShowProfileScreen(true);
        setNewCurrentTabActive();
      }

      setResult(true);
      return true;
    } else {
      handleErrorResponse(loginJson);
      return false;
    }
  };
  const reloginStep: ProvisionZeroStepCallback = async (): Promise<boolean> => {
    console.info("Removing old accessToken before relogin");
    setLocalAccessToken(""); // so all followings request should fail and kick out if necessary
    const reloginFirstResult = await relogin();
    const reloginJson =
      reloginFirstResult?.ok && reloginFirstResult?.body
        ? ((await reloginFirstResult.json()) as WebGwResponse)
        : undefined;
    if (
      reloginFirstResult?.ok &&
      reloginJson?.notificationList?.loginResultNotification?.access_token
    ) {
      const accessToken =
        reloginJson.notificationList?.loginResultNotification?.access_token;
      if (accessToken?.length > 0) {
        console.info("Saving new accessToken:", accessToken);
        setLocalAccessToken(accessToken);
        checkAndRefetchConfig(true);

        return true;
      }
    }
    //todo: or only setLocalAccessToken(""); while timeout, up2U
    return false;
  };

  const provWaitRequest: ProvisionFirstStepCallback = async (
    user,
    deviceName,
    force
  ) => {
    try {
      const registrationResponse = await register(user, deviceName, force);
      const registrationJson =
        registrationResponse?.ok && registrationResponse?.body
          ? ((await registrationResponse?.json()) as WebGwResponseOTP)
          : undefined;
      const accessToken =
        registrationJson?.notificationList?.loginResultNotification
          ?.access_token;
      if (registrationResponse?.ok && accessToken) {
        setLocalAccessToken(accessToken);
        return true;
      } else {
        if (registrationResponse?.status === 409) {
          transferSession();
          return false;
        } else {
          console.error("Error registering.");
          handleErrorResponse(
            registrationResponse?.body
              ? await registrationResponse?.json()
              : undefined
          );
          cancelAll();
        }
      }
    } catch (error) {
      console.error("Error while registering: ", error);
      handleErrorResponse(undefined);
      cancelAll();
    }
    return false;
  };

  const provisionRequest: ProvisionCallback = async (
    user
  ): Promise<boolean> => {
    const accessToken = getLocalAccessToken();
    if (accessToken === "" || !accessToken) {
      console.error("No accessToken, provisioning failed");
      return false;
    }
    let webGwResponse: WebGwResponse | undefined;
    let provWaitObj;
    try {
      if (user === undefined) {
        provWaitObj = await provWait(accessToken);
        webGwResponse = provWaitObj.body
          ? ((await provWaitObj.json()) as WebGwResponse)
          : undefined;
        console.log("webGwResponse: ", webGwResponse);
      }
      if (user !== undefined) {
        // Dont forget to init the signal here since it could have been aborted previously
        provisionAbortController.current = new AbortController();
        //provWaitAfterOtp is used here so that we dont have a timeout on device management screen
        provWaitObj = await provWaitAfterOtp(
          accessToken,
          provisionAbortController.current.signal
        );
        webGwResponse = provWaitObj.body
          ? ((await provWaitObj.json()) as WebGwResponse)
          : undefined;
        console.log("webGwResponse: ", webGwResponse);
      }
      if (
        provWaitObj.ok &&
        [PROVISIONED_SUCCESS, LOGGED_IN_SUCCESS].includes(
          (webGwResponse?.notificationList?.loginResultNotification
            ?.result as string) ?? ""
        )
      ) {
        console.log("Received loggedin response from webgw");
        // these can be done in parallel. They are awaited after login.
        return loginAfterProv(
          accessToken,
          user,
          webGwResponse?.notificationList?.loginResultNotification
            .firstTimeUser,
          true
        );
      } else if (
        provWaitObj.ok &&
        webGwResponse?.notificationList?.loginResultNotification?.request ===
          GET_DEVICE_LIST
      ) {
        console.log("Received device list from webgw");
        console.log(
          "result:",
          provWaitObj?.notificationList?.loginResultNotification?.result
        );
        setDevices(
          (
            webGwResponse?.notificationList?.loginResultNotification
              ?.result as DeviceNotification
          )?.DeviceList
        );
        setMaxDevices(
          String(
            (
              webGwResponse?.notificationList?.loginResultNotification
                ?.result as DeviceNotification
            )?.MaxDevice
          )
        );
        setCurrent(ProvisioningSteps.StepRemoveDevice);
        return false;
      } else {
        console.log("no valid responses from webgw");
        if (!provisionAbortController.current.signal.aborted)
          handleErrorResponse(webGwResponse);
      }
      removeLocalAccessToken();
      setCurrent(ProvisioningSteps.StepLogin);
      return false;
    } catch (_error) {
      handleErrorResponse(webGwResponse);
      cancelAll();
      return false;
    }
  };

  function handleErrorResponse(
    payload: WebGwResponse | WebGwResponseOTP | undefined
  ) {
    let statusCode =
      payload?.notificationList?.loginResultNotification?.status_code;

    // status code could be a string, parse it for the switch case below to work
    if (typeof statusCode === "string") {
      statusCode = parseInt(statusCode);
    }

    let reason =
      "We're experiencing issues with our service. Please try again later.";

    switch (statusCode) {
      case LOGIN_RESULT_NOTIFICATION_STATUS_CODE.ImdnWrongFormat:
        reason = `The phone number entered does not seem to be valid. Do not use "-" or "()". Please review and try again.`;
        break;
      case LOGIN_RESULT_NOTIFICATION_STATUS_CODE.OtpAlreadyUsed:
      case LOGIN_RESULT_NOTIFICATION_STATUS_CODE.OtpExpired:
      case LOGIN_RESULT_NOTIFICATION_STATUS_CODE.OtpWrong:
        reason =
          "The verification code entered is invalid. Please review and try again.";
        break;

      case LOGIN_RESULT_NOTIFICATION_STATUS_CODE.OtpNotProvided:
      case LOGIN_RESULT_NOTIFICATION_STATUS_CODE.TimeOut:
        reason = "The verification code entered has expired. Please try again.";
        break;
    }

    // TODO - is this still needed? Webgw always return TimeOut when no otp provided, check with backend team this use case.
    if (statusCode === LOGIN_RESULT_NOTIFICATION_STATUS_CODE.OtpNotProvided) {
      logoutEvent();
    }
    console.error(
      "Failed to provision with reason:",
      payload?.notificationList?.loginResultNotification?.reason,
      ", statusCode:",
      statusCode
    );
    toast(reason);
  }

  // TODO: Show the user a reason for why it failed and what he needs to do.
  const cancelAll = () => {
    console.info("Failed to provision, restarting provisioning");
    setCurrent(ProvisioningSteps.StepLogin);
  };

  const otprequired = () => {
    setCurrent(ProvisioningSteps.StepWaitForOtp);
  };

  const transferSession = () => {
    setCurrent(ProvisioningSteps.StepTransferSession);
  };

  const finishRegistration = () => {
    setResult(true);
    setCurrent(ProvisioningSteps.StepRegistered);
  };

  const handleRedirectToReloginPage = () =>
    setCurrent(ProvisioningSteps.StepHandleDisconnectLoginPage);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = async () => {
    if (3 == current) await abortProvisioning();
    setCurrent(current - 1);
  };

  const nextRememberMe = () => {
    setCurrent(current + 2);
  };

  const otpSent = () => {
    setCurrent(ProvisioningSteps.StepRegistered);
  };

  const deviceRemoved = async () => {
    if (await loginAfterProv(getLocalAccessToken(), getLocalUser()))
      setCurrent(ProvisioningSteps.StepRegistered);
  };

  const switchTabStep = async () => {
    const deviceName = getDeviceName() ?? "";
    if (await provWaitRequest(currentUser, deviceName, true)) {
      setCurrent(ProvisioningSteps.StepWaitForOtp);
    }
  };

  const navigate = useNavigate();

  console.log("fromOdiencePreview:", fromOdiencePreview);
  console.log("odienceEventId:", odienceEventId);
  const handleCloseProvisioning = () => {
    if (fromOdiencePreview && odienceEventId) {
      navigate(
        generatePath(paths.previewOdienceStream, {
          eventId: odienceEventId,
        })
      );
    } else if (fromOdiencePreview) {
      navigate(paths.previewOdience);
    } else {
      navigate(paths.onboarding);
    }
  };

  const steps = [
    {
      title: "preprovisioned",
      content: (
        <Step0
          next={rememberMe ? nextRememberMe : next}
          onRedirectToReloginPage={handleRedirectToReloginPage}
          provision={reloginStep}
          otprequired={otprequired}
          finish={finishRegistration}
        />
      ),
    },
    {
      title: "First",
      content: (
        <Step1 next={next} onCloseProvisioning={handleCloseProvisioning} />
      ),
    },
    {
      title: "Second",
      content: (
        <Step2
          finish={finishRegistration}
          next={next}
          provision={provWaitRequest}
          onCloseProvisioning={handleCloseProvisioning}
        />
      ),
    },
    {
      title: "Third",
      content: (
        <Step3
          otpSent={otpSent}
          provision={provisionRequest}
          prev={prev}
          navigate={handleCloseProvisioning}
        />
      ),
    },
    {
      title: "Last",
      content: <Step4 />,
    },
    {
      title: "RemoveDevice",
      content: (
        <StepRemoveDevice
          devices={devices}
          next={deviceRemoved}
          cancel={cancelAll}
          deviceLimit={maxDevices}
        />
      ),
    },
    {
      title: "StepTransferSession",
      content: <StepTransferSession next={switchTabStep} prev={cancelAll} />,
    },
    {
      title: "StepHandleDisconnectLoginPage",
      content: (
        <ReloginPage
          provision={provWaitRequest}
          onCloseProvisioning={handleCloseProvisioning}
          next={(step) => setCurrent(step)}
        />
      ),
    },
  ];

  return (
    <div css={provisioningBody}>
      <Global styles={globalStyles} />
      <Root>{steps[current].content}</Root>
    </div>
  );
};

export default Provisioning;
