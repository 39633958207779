import {
  Body,
  areaCodeBox,
  areaCodeField,
  checkbox,
  checkedCSS,
  closeButton,
  inputFieldsCSS,
  mobileNumberFields,
  options,
  registerButton,
  selectCSS,
  step2RegisterButton,
  stepBody,
  stepHeader,
  termsCSS,
  text,
  textDark,
} from "../index.style";

import { IconButton } from "@/components/shared/Button";
import { colors } from "@/styles/global.styles";
import { ProvisionFirstStepCallback } from "@/types/provisioning";
import useProvisioningInfos from "@/utils/hooks/useProvisioningInfos";
import CloseIcon from "@mui/icons-material/Close";
import OptionalProvisionLogo from "../OptionalProvisionLogo";

type Props = {
  provision: ProvisionFirstStepCallback;
  next: (next?: any) => void;
  finish: () => void;
  onCloseProvisioning: () => void;
};

const Step2 = ({ finish, next, provision, onCloseProvisioning }: Props) => {
  const {
    close,
    handleCountryChange,
    country,
    sortedCountryCodes,
    phoneNumber,
    setPhoneNumber,
    deviceName,
    setDeviceName,
    isButtonDisabled,
    handleProvision,
    fromOdiencePreview,
    checked,
    setChecked,
    unvalidPhoneNumber,
  } = useProvisioningInfos({ finish, next, onCloseProvisioning, provision });

  return (
    <>
      <Body>
        <IconButton css={closeButton}>
          <CloseIcon onClick={close} />
        </IconButton>
        <div className="stepBody" css={stepBody}>
          <OptionalProvisionLogo />
          <h1 id="stepHeader" css={[text, stepHeader]}>
            Login
          </h1>

          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            css={{ display: "flex", flexDirection: "column" }}
          >
            {/* Phone Number Input */}
            <label css={[text, textDark]}>Mobile Phone Number</label>
            <div css={[options, mobileNumberFields, { display: "flex" }]}>
              <div css={areaCodeBox}>
                <select
                  id="areaCodeField"
                  onChange={handleCountryChange}
                  value={country as string}
                  css={[selectCSS, areaCodeField]}
                >
                  {sortedCountryCodes}
                </select>
              </div>
              <input
                type="number"
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                css={[
                  inputFieldsCSS,
                  {
                    flexGrow: 1,
                    marginLeft: "1vw",
                    ...(unvalidPhoneNumber && {
                      border: `1px solid ${colors.primaryAccentColor}`,
                    }),
                  },
                ]}
                onWheel={(e) => e.currentTarget.blur()} // Prevent number scroll change
              />
            </div>

            <p css={[text, { fontSize: "0.75rem" }]}>
              You will receive an SMS to confirm your identity. Carrier charges
              may apply.
            </p>

            {/* Device Name Input */}
            <label css={[text, textDark, { marginTop: "2em" }]}>
              oPassword
            </label>
            <input
              type="text"
              placeholder="Enter oPassword"
              maxLength={100}
              value={deviceName}
              onChange={(e) => setDeviceName(e.target.value)}
              css={inputFieldsCSS}
            />

            {/* Submit Button */}
            <button
              type="submit"
              onClick={handleProvision}
              css={[
                registerButton,
                step2RegisterButton,
                {
                  fontSize: "1.2em",
                  fontWeight: "bold",
                  height: "55px",
                  width: "100%",
                },
              ]}
            >
              Login To {fromOdiencePreview ? "Odience" : "Verse"}
            </button>

            {/* Remember Me Checkbox */}
            <div
              css={[checkbox, { justifyContent: "left", paddingTop: "0.5em" }]}
            >
              <input
                type="checkbox"
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                css={checkedCSS}
              />
              <label css={termsCSS}>
                <p css={{ margin: "1em 0" }}>Using OAuth?</p>
              </label>
            </div>
          </form>
        </div>
      </Body>
    </>
  );
};

export default Step2;
