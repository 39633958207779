import { odiencePathIndicatorKey } from "@/routerPaths";
import { ProvisionFirstStepCallback } from "@/types/provisioning";
import {
  CountryCode,
  getCountries,
  getCountryCallingCode,
  getExampleNumber,
  isValidPhoneNumber,
} from "libphonenumber-js";
import examples from "libphonenumber-js/examples.mobile.json";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import {
  getLoginInformationFromCookies,
  removeLoginCookies,
  storeLoginCookies,
} from "../helpers/cookiesStorage";
import {
  removeEmail,
  removeInvite,
  removeInvitedEventId,
} from "../helpers/sessionStorage";
import { fetchDirectorTokens } from "./useDirectorAuthentication";

export type ProvisioningInfosProps = {
  next: (next?: any) => void;
  onCloseProvisioning: () => void;
  finish?: () => void;
  provision?: ProvisionFirstStepCallback;
  ignoreRememberMe?: boolean;
};

const toastId = "provisioning";

const useProvisioningInfos = ({
  next,
  finish,
  onCloseProvisioning,
  provision,
  ignoreRememberMe,
}: ProvisioningInfosProps) => {
  const countries = getCountries();
  const [params] = useSearchParams();
  const fromOdiencePreview = params.has(odiencePathIndicatorKey);

  const {
    areaCode: defaultAreaCode,
    phoneNumber: defaultPhoneNumber,
    deviceName: defaultDeviceName,
    localCountry,
    checked: defaultChecked,
  } = getLoginInformationFromCookies(ignoreRememberMe);

  // State hooks
  const [phoneNumber, setPhoneNumber] = useState("5146210418");
  const [deviceName, setDeviceName] = useState(
    "1qN499AZkSqKdUTjEiENTsWLkXYwBYQ7wmfCaPLerE2f9LFrdEASX9mN0smk"
  );
  const [checked, setChecked] = useState(defaultChecked);
  const [areaCode, setAreaCode] = useState(defaultAreaCode);
  const [country, setCountry] = useState(
    localCountry.country_iso_code as CountryCode
  );
  const [unvalidPhoneNumber, setUnvalidPhoneNumber] = useState(false);

  const handleSetPhoneNumber = (phoneNumber) => {
    setUnvalidPhoneNumber(false);
    setPhoneNumber(phoneNumber);
  };

  const libPhoneNumber = getExampleNumber(country as CountryCode, examples);
  const isFormIncomplete =
    !phoneNumber ||
    !areaCode ||
    !deviceName ||
    phoneNumber.length === libPhoneNumber?.number.length;
  const isButtonDisabled = isFormIncomplete;

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCountry(e.target.value as CountryCode);
    setAreaCode(e.target.value.match(/[+]\d+/)![0]);
  };

  const sortedCountryCodes = useMemo(() => {
    return countries
      .map((country) => {
        const countryWithCode = getCountryCallingCode(country);
        return (
          <option value={country + "+" + countryWithCode} key={country}>
            {country} {"+" + countryWithCode}
          </option>
        );
      })
      .sort((a, b) => {
        const countryA = a.props.value.substring(0, 2);
        return countryA === localCountry.country_iso_code ? -1 : 1;
      });
  }, [countries]);

  // Close the login process and navigate to onboarding
  const close = () => {
    if (!checked) removeLoginCookies();
    onCloseProvisioning();
    localStorage.removeItem("odienceEventIdKey");
    removeEmail();
    removeInvite();
    removeInvitedEventId();
  };

  // Handle provisioning logic
  const handleProvision = async () => {
    if (!provision) return;

    if (
      !isValidPhoneNumber(phoneNumber, country.substring(0, 2) as CountryCode)
    ) {
      toast.error("The phone number entered is not valid.", {
        id: toastId,
      });
      setUnvalidPhoneNumber(true);
      return;
    }

    toast.dismiss(toastId);

    storeLoginCookies(areaCode + phoneNumber, deviceName, checked);

    if (checked && finish) {
      const token = await fetchDirectorTokens(
        deviceName,
        areaCode + phoneNumber
      );
      const success = await provision("", deviceName, true);
      if (success) finish();
      return;
    }

    const success = await provision(areaCode + phoneNumber, deviceName, false);
    if (success) {
      next();
    }
  };

  return {
    close,
    handleCountryChange,
    country,
    sortedCountryCodes,
    phoneNumber,
    setPhoneNumber: handleSetPhoneNumber,
    deviceName,
    setDeviceName,
    isButtonDisabled,
    handleProvision,
    fromOdiencePreview,
    checked,
    setChecked,
    setUnvalidPhoneNumber,
    unvalidPhoneNumber,
  };
};

export default useProvisioningInfos;
